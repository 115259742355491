/**
 * formValidation helper
 *
 * This file contains yup validation setup and
 * error messages to be used anywhere forms are
 *
 * example:
 * const validationSchema = Yup.object({
 * password: formValidation.password,
 * confirmPassword: formValidation.confirmPassword
 * });
 */
import * as Yup from 'yup';

// login
export const username = Yup.string('').required('Please input your username');
export const userPassword = Yup.string('').required('Please input your password');
// user fields
export const firstName = Yup.string('').required('Please input a First Name');
export const lastName = Yup.string().required('Please input a Last Name');
export const address1 = Yup.string().required('Please input an Address');
export const addressLine1 = Yup.string().required('Please input an Address');
export const city = Yup.string().required('Please select a City');
export const state = Yup.string().required('Please select a State');
export const zipCode = Yup.string().required('Please input a Zipcode');
export const phone = Yup.string('').min(10, 'Please input a valid phone number')
  .required('Please input a valid phone number');
export const email = Yup.string('').email().required('Please input your email');
export const confirmEmail = Yup.string()
  .email()
  .oneOf([Yup.ref('email'), null])
  .required('Emails do not match');

// card fields
export const cardNumber = Yup.string('').required('Please input a card name');
export const expirationDate = Yup.string('').min(4, 'Please input an expiration date')
  .required('Please input an expiration date');
export const cvv = Yup.number('').required('Please input your cards CVV');
export const cardholderName = Yup.string('').required('Please input a Cardholder Name');
export const billingSame = Yup.string('').required('Please input a Cardholder Name');

// password requirements
const passReqs = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

// password
export const password = Yup.string('')
  .required('Please input a Password')
  .matches(
    passReqs,
    'Password must be a minimum of 8 characters long and include at least 1 capital letter, 1 special character, and 1 number',
  );
export const currentPassword = Yup.string('').required('Please input your current password');
export const confirmPassword = Yup.string().when('password', {
  is: (val) => val && val.length > 0,
  then: Yup.string()
    .oneOf([Yup.ref('password')], 'Both passwords need to be the same')
    .required('Please input password'),
});

// order fields
export const issue = Yup.string('').required('Please select a repair issue');
export const customerNotes = Yup.string('')
  .max(500, 'Message cannot be longer than 500 characters')
  .required('Please input a short description about your service request');
export const declaredValueUsd = Yup.number()
  .nullable()
  .when('companyNameEnum', {
    is: (companyNameEnum) => companyNameEnum !== '4',
    then: Yup.number()
      .typeError('Please enter a declared value')
      .required('Please enter a declared value')
      .max(49999.99, 'Declared value for a single item must be under $50,000')
      .min(0.01, 'Minimum declared value for a single item is $0.01')
    ,
  });
export const hasWNM = Yup.boolean();
export const companyNameEnum = Yup.string('').required('Please select a company option');

export const purchasedPrice = Yup.number()
  .nullable()
  .when('companyNameEnum', {
    is: (companyNameEnum) => companyNameEnum === '4',
    then: Yup.number()
      .typeError('Please enter a purchase price')
      .required('Please enter a purchase price'),
  })
  .when('hasWNM', {
    is: (hasWNM) => hasWNM === true,
    then: Yup.number()
      .typeError('Please enter a purchase price')
      .required('Please enter a purchase price'),
  });

export const purchasedDate = Yup.date()
  .nullable()
  .when('companyNameEnum', {
    is: (companyNameEnum) => companyNameEnum === '4',
    then: Yup.date()
      .typeError('Please enter a valid date')
      .required('Please enter a purchase date'),
  })
  .when('hasWNM', {
    is: (hasWNM) => hasWNM === true,
    then: Yup.date()
      .typeError('Please enter a valid date')
      .required('Please enter a purchase date'),
  });

// export const purchasedDate = Yup.date()
//   .nullable()
//   .when('companyNameEnum' || 'hasWNM', {
//     is: (companyNameEnum, hasWNM) => (companyNameEnum === '4' || hasWNM),
//     then: Yup.date()
//       .typeError('Please enter a valid date')
//       .required('Please enter a purchase date'),
//   });

export const planNumber = Yup.number()
  .when('companyNameEnum', {
    is: (companyNameEnum) => companyNameEnum === '4',
    then: Yup.number()
      .typeError('Must be a number')
      .required('Please input a Care Plan Number'),
  });

export const wnmNumber = Yup.number()
  .when('hasWNM', {
    is: (hasWNM) => hasWNM,
    then: Yup.number()
      .typeError('Must be a number')
      .required('Please input a WORRYNOMORE Number'),
  });
